<template lang="html">
  <div class="place">
    <div class="head">
      <img src="../../assets/image/suozaidiBk.png" alt="">
    </div>
    <div class="box">
      <div class="title">
        填写户籍所在地
      </div>
      <div class="content">
        <van-field v-model="merchantsCardIdAddress" type="textarea" placeholder="请输入身份证上的户籍所在地" />
      </div>
      <div class="btn">
        <van-button type="primary" round @click="confirm">确定</van-button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'place',
    data() {
      return {
        merchantsCardIdAddress: ''
      };
    },
    created() {
      this.getDetail();
    },
    methods: {
      getDetail() {
        this.$require.HttpPost('/h5/merchants/getMerchants').then(res => {
          if (res.data) {
            this.merchantsCardIdAddress = res.data.merchants_card_id_address;
          }
        });
      },
      confirm() {
        if (!this.merchantsCardIdAddress) {
          this.$toast('请输入户籍所在地');
          return;
        }
        this.$require.HttpPost('/h5/merchants/changeAddress', { merchants_card_id_address: this.merchantsCardIdAddress }).then(() => {
          this.$toast('添加成功');
          setTimeout(() => {
            this.$router.go(-1);
          }, 500);
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .place {
    padding: 0 24px;
    .head {
      img {
        width: 386px;
        height: 277px;
        display: block;
        margin: 79px auto 0 auto;
      }
    }
    .box {
      box-shadow: 0px 0px 25px 0px rgba(57, 57, 57, 0.06);
      border-radius: 30px;
      padding: 0 20px 60px 20px;
      margin-top: 60px;
      .title {
        height: 130px;
        line-height: 130px;
        font-size: 32px;
        color: #000000;
        padding: 0 20px;
      }
      .content {
        .van-cell {
          height: 130px;
          background: #F8F8F8;
          border-radius: 15px;
          padding: 10px 20px;
        }
      }
      .btn {
        width: 468px;
        height: 96px;
        margin: 60px auto 0 auto;
      }
    }
  }
</style>
